@import "./variables.scss";
@import "./mixins.scss";

.section-1 {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .infos {
        width: 80%;
        border-radius: $border-radius;
        height: 100%;
        background-color: #fff;
        box-shadow: 0 5px 20px $main-color-shadow;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        transition: $transition;
        @include breakpoint(md) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        @include breakpoint(sm) {
            width: 90%;
        }
        .image {
            width: 60%;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img {
                height: 100%;
                @include breakpoint(md) {
                    height: auto;
                    width: 100%;
                }
                @include breakpoint(smBxs) {
                    height: 100%;
                    width: auto;
                }
            }
            @include breakpoint(md) {
                width: 100%;
                box-shadow: 0 5px 10px $main-color-shadow3;
                align-items: flex-start;
            }
        }
        .data {
            width: 35%;
            height: 100%;
            min-height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            transition: $transition;
            gap: 10px;
            padding: 0 10px;
            &>p{
                max-width: 90%;
            }
            @include breakpoint(md) {
                justify-content: flex-start;
                padding: 20px 10px 0 10px;
                width: 100%;
            }
            .contact-info{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                margin-top: 20px;
                @include breakpoint(md) {
                    gap: 10px;
                    margin-top: 10px;
                }
                &>div{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    svg {
                        color: $color-info;
                    }
                    p {
                        font-weight: 500;
                        letter-spacing: 1px;
                        color: $color-gray-800;
                    }
                }
            }
        }
    }
}