@import "./variables.scss";
@import "./mixins.scss";

.wait-section{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgb(250,233,154);
    background: linear-gradient(163deg, rgba(250,233,154,0.5) 0%, rgba(240,220,255,0.5) 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &>div{
        width: 30%;
        transition: $transition;
        @include breakpoint(xl) {
            width: 40%;
        }
        @include breakpoint(lg) {
            width: 50%;
        }
        @include breakpoint(md) {
            width: 60%;
        }
        @include breakpoint(sm) {
            width: 70%;
        }
    }
}

.main {
    position: relative;
    width: 100%;
    height: 100vh;
    background: rgb(250,233,154);
    background: linear-gradient(163deg, rgba(250,233,154,1) 0%, rgba(240,220,255,1) 100%);
    padding-top: 130px;
    .head {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transition: $transition;
        padding-top: 7px;
        z-index: 100;
        .head-body{
            width: 85%;
            background-color: #695d6099;
            border-radius: $border-radius;
            backdrop-filter: blur(5px);
            padding: 10px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @include breakpoint(sm) {
                width: 94%;
            }
            .title {
                font-size: 22px;
                font-weight: 500;
                text-shadow: 1px 1px 1px rgba(195, 195, 195, 0.6);
                span{
                    color: $color-red;
                    margin: 0 4px;
                }
            }
            .menu-swip{
                .menu-btn{
                    color: $font-color-dark;
                    background-color: rgba(255, 255, 255, 0.4);
                    &:hover{
                        background-color: rgba(255, 245, 245, 0.7);
                    }
                    &>div{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        padding: 1px;
                        span{
                            margin-top: 3px;
                            @include breakpoint(sm) {
                               display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-list-items{
    svg {
        color: #fff;
        font-size: 22px;
    }
    a{
        text-decoration: none;
        color: #fff;
    }
}

.menu-language-items{
    color: #fff;
    .image {
        width: 32px;
        height: 21px;
        border-radius: 3px;
        box-shadow: 0 0 7px rgba(255, 255, 255, 0.4);
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
            height: 100%;
        }
    }
}