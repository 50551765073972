/*** colors ***/
$main-color: #EA738D;
$main-color2: #ffe987;
$main-color3: #4831D4;
$main-color4: #23de90;
$main-color-shadow: #aa5560ab;
$main-color-shadow3: #a5a1ba;
$main-color-shadow4: #1ec17d;
$gray-light: #dddddd;
$btn-back: #979aa0;
$btn-back-hover: #73777f;
$font-color-dark: #2d1e27;

$color-blue: #63b3ed;
$color-indigo: #596cff;
$color-purple: #6f42c1;
$color-pink: #d63384;
$color-red: #f56565;
$color-orange: #fd7e14;
$color-yellow: #fbd38d;
$color-green: #81e6d9;
$color-teal: #20c997;
$color-cyan: #0dcaf0;
$color-gray: #6c757d;
$color-gray-dark: #343a40;
$color-gray-100: #f8f9fa;
$color-gray-200: #f0f2f5;
$color-gray-300: #dee2e6;
$color-gray-400: #ced4da;
$color-gray-500: #adb5bd;
$color-gray-600: #6c757d;
$color-gray-700: #495057;
$color-gray-800: #343a40;
$color-gray-900: #212529;
$color-primary: #e91e63;
$color-secondary: #7b809a;
$color-success: #4caf50;
$color-info: #1a73e8;
$color-warning: #fb8c00;
$color-danger: #f44335;
$color-light: #f0f2f5;
$color-dark: #344767;
$color-white: #fff;
$color-white-hover: rgba(255, 255, 255, 0.272);
$color-dark-blue: #1a237e;

/*** border ***/
$border-radius: 15px !important;
$border-radius-inner: 10px;
$border: 1px solid $gray-light !important;

/*** transition ***/
$transition: all 0.4s ease;