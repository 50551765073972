@import "./variables.scss";
@import "./mixins.scss";

.section-5 {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    .back-image{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('./../Utils/Assets/Images/idea-back.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 -400px;
        transition: $transition;
        z-index: 1;
        @include breakpoint(xl) {
            background-position: 0 -250px;
        }
        @include breakpoint(lg) {
            background-position: 0;
        }
    }
    .back-blur{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);
        background: rgb(53,41,120);
        background: linear-gradient(45deg, rgba(53,41,120,0.8) 0%, rgba(255,245,188,0.6) 100%); 
        z-index: 2;
    }
    .send-mail{
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 3;
        @include breakpoint(lg) {
            width: 80%;
        }
        @include breakpoint(md) {
            width: 70%;
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 30px;
            gap: 10px;
        }
        &>div{
            width: 60%;
            padding: 10px;
            p{
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 2px;
                font-weight: 500;
                color: $main-color2;
                text-shadow: 1px 1px 1px rgb(53, 53, 53);
                svg {
                    color: $main-color;
                }
            }
            @include breakpoint(md) {
                width: 100%;
            }
        }
        .email-form{
            width: 40%;
            height: 85%;
            padding: 20px;
            border-radius: $border-radius;
            border: 1px solid #fff;
            box-shadow: 0 4px 0 #fff;
            background-color: rgba(255, 255, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            @include breakpoint(md) {
                width: 100%;
            }
        }
    }
}