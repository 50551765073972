@mixin breakpoint($point) {
    @if $point == xxl {
        @media (max-width: 1400px) {
            @content;
        }
    } @else if $point == xl {
        @media (max-width: 1200px) {
            @content;
        }
    } @else if $point == lg {
        @media (max-width: 992px) {
            @content;
        }
    } @else if $point == md {
        @media (max-width: 768px) {
            @content;
        }
    } @else if $point == sm {
        @media (max-width: 576px) {
            @content;
        }
    } @else if $point == smBxs {
        @media (max-width: 436px) {
            @content;
        }
    } @else if $point == xs {
        @media (max-width: 368px) {
            @content;
        }
    } @else if $point == maxlg {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $point == maxmd {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $point == maxsm {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $point == maxxs {
        @media (min-width: 368px) {
            @content;
        }
    }
}