@import "./variables.scss";
@import "./mixins.scss";

.section-2 {
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    .about {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        .section-title {
            font-weight: 500;
            font-size: 25px;
            letter-spacing: 1px;
        }
        .section-body {
            @include breakpoint(maxsm) {
                text-align: justify;
            }
            svg {
                color: $main-color;
                margin-bottom: 3px;
                margin-right: 5px;
            }
        }
    }
}