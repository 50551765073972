.flex-column-start-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-column-center-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.flex-column-center-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-column-start-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.flex-row-center-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-row-center-start {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
}

.flex-row-between-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-row-start-center {
    display: flex;
    flex: row;
    justify-content: flex-start;
    align-items: center;
}

.flex-row-start-start {
    display: flex;
    flex: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-row-end-center {
    display: flex;
    flex: row;
    justify-content: flex-end;
    align-items: center;
}