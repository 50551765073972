@import "./variables.scss";
@import "./mixins.scss";

.section-4 {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 100px;
    color: $color-dark;
    transition: $transition;
    @include breakpoint(sm) {
        padding: 50px 20px;
    }
    .section-body{
        width: 90%;
        background-color: #fff;
        flex-direction: row;
        border-radius: $border-radius;
        box-shadow: 0 0 15px $main-color-shadow3;
        .tab-container {
            border-radius: $border-radius-inner $border-radius-inner 0 0;
            background-color: $color-gray-300;
        }
        .tabs{
            margin-bottom: 7px;
            transition: $transition;
        }
        .active{
            background-color: lighten($main-color-shadow, 35%);
            color: $main-color-shadow;
        }
        .infos {
            width: 100%;
            padding: 15px;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            transition: $transition;
            gap: 25px;
            @include breakpoint(md) {
                gap: 20px;
            }
            .each{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .text{
                    width: 60%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: nowrap;
                    padding-right: 20px;
                    transition: $transition;
                    @include breakpoint(md) {
                        padding-right: 15px;
                    }
                    @include breakpoint(sm) {
                        width: 80%;
                        padding-right: 10px;
                    }
                    p{
                        padding-right: 20px;
                        white-space: nowrap;
                        transition: $transition;
                        @include breakpoint(sm) {
                            padding-right: 10px;
                            font-size: 14px;
                        }
                    }
                    .dashed{
                        width: 100%;
                        height: 1px;
                        border: 1px dashed $main-color-shadow3;
                    }
                }
                .percent{
                    width: 40%;
                    padding: 2px;
                    height: 21px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: $color-dark;
                    border-radius: 10px;
                    overflow: hidden;
                    @include breakpoint(sm) {
                        display: none;
                    }
                    &>div{
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        background-color: #f0f0f0;
                    }
                }
                .percent-number{
                    border-radius: 7px;
                    background-color: #f0f0f0;
                    padding: 3px 5px;
                    @include breakpoint(maxsm) {
                        display: none;
                    }
                }
            }
        }
    }
}