@import "./variables.scss";
@import "./mixins.scss";

.section-3 {
    width: 100%;
    height: 400px;
    background: rgb(87,87,87);
    background: linear-gradient(163deg, rgb(205, 191, 205) 0%, rgb(179, 177, 168) 100%);
    position: relative;
    .caret-show {
        position: absolute;
        z-index: -1;
        svg {
            font-size: 170px;
            color: $main-color4;
            opacity: 0.2;
        }
        @include breakpoint(maxmd) {
            svg {
                font-size: 200px;
            }
        }
        @include breakpoint(maxlg) {
            svg {
                font-size: 210px;
            }
        }
        @include breakpoint(xs) {
            display: none;
        }
    }
    .caret-up {
        top: -90px;
        left: 50px;
        @include breakpoint(maxmd) {
            top: -130px;
            left: 40px;
        }
        @include breakpoint(maxlg) {
            top: -135px;
            left: 30px;
        }
    }
    .caret-down {
        bottom: -90px;
        right: 50px;
        @include breakpoint(maxmd) {
            bottom: -130px;
            right: 40px;
        }
        @include breakpoint(maxlg) {
            bottom: -135px;
            right: 30px;
        }
    }
    .rect{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        z-index: -1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        transition: $transition;
        @include breakpoint(md) {
            width: 70%;
            height: 70%;
        }
        &>div{
            width: 300px;
            height: 400px;
            background-color: rgba(35, 222, 144, 0.6);
            box-shadow: 0 0 7px rgba(35, 222, 144, 1);
            transform: rotate(-45deg);
            border-radius: 20px 20px 200px 20px;
        }
    }
    .drtl{
        left: 0;
        padding-left: 15%;
    }
    .dltr{
        right: 0;
        padding-right: 15%;
    }
    .slogan{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('./../Utils/Assets/Images/black-felt.png');
        opacity: 0.7;
        p{
            width: 80%;
            text-align: center;
            font-size: 35px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $font-color-dark;
            span {
                font-weight: 500;
                color: rgb(35, 222, 144);
            }
        }
    }
}