@import './font.scss';
@import './variables.scss';


.persian-font {
    font-family: "IRANSans" !important;
    letter-spacing: 0 !important;
}

.force-en-font {
    font-family: "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

p {
    margin: 0 !important;
}

input {
    width: 100%;
    border-radius: 7px;
    border: none;
    outline: none;
    height: 40px;
    padding: 0 10px;
    flex-shrink: 0;
    background-color: #FCF6F5;
    border: 2px solid #cacaca;
    &:hover{
        border: 2px solid $main-color-shadow;
    }
    &:focus{
        border: 2px solid $main-color;
    }
}

input[type=submit] {
    background-color: $main-color4;
    border: none;
    &:hover, &:focus{
        background-color: $main-color-shadow4;
    }
}

textarea {
    width: 100%;
    height: 180px;
    resize: none !important;
    border-radius: 7px;
    border: none;
    outline: none;
    min-height: 100px;
    padding: 10px;
    background-color: #FCF6F5;
    border: 2px solid #cacaca;
    &:hover{
        border: 2px solid $main-color-shadow;
    }
    &:focus{
        border: 2px solid $main-color;
    }
}

.handC{
    cursor: pointer;
}