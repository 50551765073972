@import "./variables.scss";
@import "./mixins.scss";

.section-7 {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgb(35,27,80);
    background: linear-gradient(94deg, rgba(35,27,80,1) 0%, rgba(73,27,80,1) 46%, rgba(39,4,52,1) 100%);
    // background: linear-gradient(94deg, rgb(108, 106, 98) 0%, rgb(111, 100, 119) 100%);
    .section-body{
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        @include breakpoint(lg) {
            flex-direction: column;
            gap: 10px;
            padding: 20px 0;
        }
        .footer{
            height: 284px;
            background-color: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.3);
            box-shadow: 0 4px 0 rgba(255, 255, 255, 0.3);
            border-radius: $border-radius;
            padding: 5px;
            padding-top: 0;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            transition: $transition;
            .title{
                width: auto;
                max-width: 95%;
                overflow: hidden;
                padding: 0 12px 5px 12px;
                white-space: nowrap;
                height: 28px;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: #fcefb4a9;
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                color: #2F3C7E;
                transition: $transition;
                p{
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: 300;
                    text-shadow: 0.5px 0.5px 1px rgb(53, 53, 53);
                }
                @include breakpoint(md) {
                    font-size: 14px;
                }
                @include breakpoint(sm) {
                    font-size: 12px;
                }
            }
            &:first-child{
                width: 30%;
                min-width: 240px;
                @include breakpoint(lg) {
                    width: auto;
                }
                @include breakpoint(sm) {
                    width: 100%;
                }
            }
            &:last-child{
                width: 70%;
                @include breakpoint(lg) {
                    width: 100%;
                }
            }
            .contact-info{
                padding: 20px;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                &>div{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    svg{
                        color: #fcefb4a9;
                    }
                    a {
                        text-decoration: none;
                        color: #fff;
                        font-weight: 300;
                    }
                }
            }
            .interesting-items{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                overflow: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                gap: 10px;
                .interesting-item{
                    width: 220px;
                    height: 220px;
                    flex-shrink: 0;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: $border-radius;
                    border: 1px solid rgba(255, 255, 255, 0.4);
                    padding: 5px;
                    &>div{
                        width: 100%;
                        height: 83%;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        overflow: hidden;
                        background-color:  rgba(255, 255, 255, 0.2);;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        img{
                            height: 100%;
                        }
                    }
                    &>p{
                        width: 100%;
                        height: 15%;
                        text-align: center;
                        background-color:  #fcefb4a9;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        color: #2F3C7E;
                    }
                }
            }
        }
    }
}