@import "./variables.scss";
@import "./mixins.scss";

.section-6 {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .section-body{
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        color: $font-color-dark;
        transition: $transition;
        @include breakpoint(lg) {
            width: 80%;
            gap: 15px;
        }
        @include breakpoint(md) {
            width: 85%;
            gap: 10px;
        }
        @include breakpoint(sm) {
            width: 90%;
        }
        .each{
            min-width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            gap: 10px;
            .image{
                width: 90px;
                height: 90px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                box-shadow: 0 3px 2px $main-color3;
                color: $main-color3;
                transition: $transition;
                img{
                    height: 100%;
                }
                svg {
                    font-size: 50px;
                    transition: $transition;
                    @include breakpoint(lg) {
                        font-size: 45px;
                    }
                    @include breakpoint(md) {
                        font-size: 40px;
                    }
                    @include breakpoint(sm) {
                        font-size: 35px;
                    }
                }
                @include breakpoint(lg) {
                    width: 80px;
                    height: 80px;
                }
                @include breakpoint(md) {
                    width: 75px;
                    height: 75px;
                }
                @include breakpoint(sm) {
                    width: 70px;
                    height: 70px;
                }
                @include breakpoint(xs) {
                    width: 60px;
                    height: 60px;
                }
            }
            .name{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                transition: $transition;
                @include breakpoint(sm) {
                    margin-top: 7px;
                    font-size: 14px;
                }
                @include breakpoint(xs) {
                    margin-top: 5px;
                    font-size: 12px;
                }
            }
        }
    }
}